.kunnskapsmodellen {
  &__image {
    width: 100%;
    text-align: center;
    img {
      width: 300px;
      margin-bottom: 32px;
    }
  }
}
