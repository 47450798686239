.omraderliste {
  margin: 0;
  width: 100%;

  &__omrade {
    margin-bottom: 8px;
    transition: all ease-in 0.2s;

    .MuiButton-root {
      width: 100%;
      text-transform: none;
      outline: none;
    }

    .MuiAccordionDetails-root {
      flex-direction: column;
    }
  }
}
