.oppgave {
  &__summary {
    .MuiAccordionSummary-content {
      display: flex;
      align-items: center;

      & > * {
        margin: 0;

        &:first-child {
          margin: 0;
          margin-right: 16px;
        }
      }
    }
  }

  & > .MuiPaper-root {
    margin-bottom: 8px;
  }

  .MuiAccordionDetails-root {
    display: flex;
    flex-flow: column nowrap;
  }

  .MuiButton-root {
    text-transform: inherit;
    width: 100%;
  }
}

.bl-accordion__header > .bl-accordion__header-content {
  padding: 0 8px;
}
