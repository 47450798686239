#faser {
  min-height: 220px;

  .column {
    .stepper {
      max-height: 135px;
      background-color: rgba(0, 0, 0, 0);
    }

    .MuiStep-root {
      cursor: pointer;
    }

    .step {
      &__iconsContainer {
        position: relative;
        margin-left: -1.2em;
      }

      &__iconsContainerMargin {
        margin-left: -1.5em;
      }

      &__title {
        text-align: center;
        margin-bottom: 16px;
        min-height: 70px;

        &--doNotDisplayIcon {
          .MuiStepLabel-iconContainer {
            display: none;
          }

          .MuiStepLabel-labelContainer {
            .MuiTypography-body2 {
              font-size: inherit;
              font-family: inherit;
            }

            h3 {
              font-weight: bold;
              font-size: 16px;
            }
          }
        }
      }

      &__icon {
        position: absolute;
        background: #737373;
        padding: 9px 9px 7px;
        border-radius: 60px;
        border: 1px solid white;
        transition: all ease-in 0.2s;
        // #737373

        svg {
          width: 24px;
          height: 24px;
        }
      }

      &__faseIconReplacement {
        position: absolute;
        background: #737373;
        border: white solid 1.7px;
        border-radius: 60px;
        width: 44px;
        height: 44px;
        transition: all ease-in 0.2s;
      }

      &__prosessIconReplacement {
        width: 47px;
        height: 44px;
        border-radius: 100%;
        transition: all ease-in 0.2s;
        z-index: 5;

        &:hover {
          background-color: white;
          border: solid #2f6341 3px;
        }
      }

      &__prosessIconReplacementUnselected {
        background-color: #2f6341;
        border: solid white 3px;
      }

      &__prosessIconReplacementSelected {
        background-color: white;
        border: solid #2f6341 3px;
      }

      &:first-child {
        &__iconsContainer {
          margin-right: 24px;
        }
      }

      &:last-child {
        &__iconsContainer {
          margin-left: 24px;
        }
      }
    }

    &--notTheActiveFase {
      padding: 0 2em;

      .MuiStep-root:hover .step__icon {
        background: #2f6341;
      }

      .MuiStep-root:hover .step__faseIconReplacement {
        background: #2f6341;
      }
    }

    &--theActiveFase {
      .MuiStep-root:hover .step__icon {
        background: white;
        border: 3px solid #2f6341;

        path {
          fill: #737373;
        }
      }

      .MuiStep-root.step--negativesort:hover .step__icon {
        background: white;
        border: 3px solid #2583ab;

        path {
          fill: #2583ab;
        }
      }

      .step {
        &__title {
          &--doNotDisplayIcon {
            .MuiStepLabel-labelContainer {
              h3 {
                font-size: 24px;
              }
            }
          }
        }
      }
    }

    &--faseIsSelected {
      border-left: 1px dashed #737373;

      &:first-child {
        border-left: none;
      }
    }

    &--prosess-label {
      min-width: 4.8rem;
      overflow-wrap: anywhere;
    }

    &--theActiveFase {
      flex-basis: auto;

      .stepper {
        margin-top: -1em;
      }

      .step {
        &__icon {
          position: inherit;
          background: #a6c4b0;
          padding: 10px 12px;
          border: 3px solid white;
          z-index: 1;

          &--active {
            background: white;
            border: 3px solid #a6c4b0;
          }
        }

        &--active {
          .MuiStepLabel-label {
            font-weight: bold;
          }
        }

        &--negativesort {
          .step__icon {
            background: #EFF5F8;
          }

          svg {
            color: #2583ab
          }

          .MuiStepConnector-root {
            display: none !important;
          }
        }
      }

      .step--active.step--negativesort {
        .step__icon {
          background: white;
          border: 3px solid #EFF5F8;
        }
      }
    }
  }
}
