.extended-view {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 5%;
  display: flex;
  flex-direction: column;

  &__header {
    padding: 16px;
    color: white;
    background: #737373;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &--title {
      display: flex;
      align-items: center;

      & > *:first-child {
        margin: 0 24px;
      }
    }

    &--closeIcon {
      cursor: pointer;
      transition: all ease-in 0.2s;

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px,
          rgb(51, 51, 51) 0px 0px 0px 3px;
      }
    }
  }

  &__content {
    max-height: 70vh;
    overflow-y: auto;
    padding: 16px;
    background: white;
  }
}
