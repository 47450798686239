@import '~buflib/src/helpers/scss/resources.scss';

.vv-grid-wrapper {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
}

.vv-grid-main {
  grid-row: 1 / 2;
  min-height: 400px;
}

.vv-grid-footer {
  grid-row: 2 / 3;
}

.vv-container {
  margin: 0 auto;
  padding: 0 5vw;
  @include bl-tablet-up() {
    width: 768px;
    padding: 0 24px;
  }
  @include bl-desktop-up() {
    width: 1200px;
  }
}

.layout {
  .message {
    margin: 0 auto;
    max-width: 1200px;
    &-header {
      svg {
        margin-right: 16px;
      }
    }
  }
}

.menu-link {
  font-weight: 700;
  color: #1a1a1a;
  text-decoration: underline;
}

.menu-link:hover {
  text-decoration: none;
}

.menu-link-column {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end; 
  align-items: center;
  padding: 10px
}