.extended-view .innholdselementliste {
  max-width: 100%;
}
.innholdselementliste__wrapper {
  & > h3 {
    margin-bottom: 8px;
  }

  .innholdselementliste {
    margin: 0;
    max-width: 100%;
    background: white;
    &__listeelement {
      width: 100%;
      margin-bottom: 8px;

      &--kilde > a {
        width: 100%;
      }

      &--sporsmal {
        .MuiCheckbox-colorSecondary.Mui-checked {
          color: #46805a;
        }

        .sporsmal__innholdselementliste {
          margin-top: 16px;
        }
      }

      &--level2 {
        .MuiPaper-elevation1 {
          box-shadow: none;
          // border: 1px solid #a6c4b0;
        }
      }

      // &--duplicate {
      //   border: 3px solid orange;
      // }

      .MuiAccordionDetails-root {
        flex-direction: column;
      }

      .MuiButton-root {
        width: 100%;
        text-transform: inherit;
      }
    }

    h3 {
      font-size: 20px;
      margin: 16px 0 8px;
    }
  }
}
