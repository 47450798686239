
.about {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    margin: auto;
}

.card-header {
    text-align: center;
    font-weight: 700;
}

.card-content {
    margin: 5px
}

.card-read-more {
    text-align: center;
    text-decoration:none;
    cursor: pointer;
}
