@import '~buflib/src/helpers/scss/resources.scss';

.vv-header {
  position: relative;
  z-index: 101;
  background-color: #f2f7f6;
  &__topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--env {
      font-size: 32px;
      font-style: normal;

      b {
        color: red;
      }
    }
  }
  &__logo {
    align-self: center;
    img {
      width: 140px;
      @include bl-tablet-up() {
        width: 200px;
      }
      
    }
  }
  &__trigger {
    padding: 0 0 0 12px;
  }
  &__megamenu {
    position: absolute;
    background: #f2f7f6;
    margin-bottom: 0;
    height: 0;
    opacity: 0;
    padding: 1em 0 2em 0;
    transition: 0.25s;
    width: 100%;
    &--open {
      height: auto;
      opacity: 1;
    }
    &--closed {
      display: none;
    }
  }

  // animating search width (transition defined in buflib component Search)
  .bl-search {
    &--closed {
      .bl-input {
        @include bl-tablet-up() {
          width: 7em;
        }
      }
    }
    &--open {
      .bl-input {
        @include bl-tablet-up() {
          width: 100%;
        }
      }
    }
  }

  &--closed {
    .bl-search {
      display: none;
      @include bl-tablet-up() {
        display: flex;
      }
    }
  }
}

.vv-background-box {
  position: absolute;
  background-color: black;
  opacity: 0.3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.vv-title {
  display: flex;
  color: black;
  font-size: large;
  font-weight: bolder;
  width: 300px;
}
