.card-stack {
  position: relative;
  margin-top: 80px;

  &__card {
    position: absolute;
    width: 100%;
    min-height: 200px;
    max-width: 600px;
    padding: 16px;
    background-color: white;
    border: 2px solid #737373;

    &--active {
      position: relative;
    }

    &--inactive {
      top: -40px;
      right: -24px;
      max-height: 200px;
      overflow: hidden;
      color: rgba($color: #000000, $alpha: 0.3);
    }

    &--inactive:last-child {
      top: -80px;
      right: -48px;
    }

    legend {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        span {
          background-color: #474646;
        }
      }

      & > * {
        margin: 0px 4px;
      }

      svg {
        background-color: white;
      }

      span {
        padding: 4px 8px;
        color: #1a1a1a;
        background-color: #737373;
        text-transform: uppercase;
        transition: all ease-in 0.2s;
      }
    }
  }

  .innholdselementliste-container {
    position: relative;
    padding-top: 24px;
    max-width: 500px;

    &__expandIcon {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: -16px;
      transition: all ease-in 0.2s;

      &:hover path {
        fill: #474646;
      }
    }
  }
}

p.MuiTypography-colorTextSecondary{
  color:#000000de;
}