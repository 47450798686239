.dimensjonerliste {
  margin: 0;

  &__dimensjon {
    margin-bottom: 8px;
    transition: all ease-in 0.2s;
    border: 3px solid #dbeae7;

    .MuiButton-root {
      width: 100%;
      text-transform: none;
      outline: none;
    }

    &.Mui-expanded {
      border: 3px solid #067c6e;
    }
    // &--17.Mui-expanded {
    //   border: 3px solid #d9330480;
    // }
    // &--24.Mui-expanded {
    //   border: 3px solid #007a8680;
    // }
    // &--29.Mui-expanded {
    //   border: 3px solid #83479980;
    // }
  }
}
