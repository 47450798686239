$FontPathOpenSans: '../node_modules/open-sans-fonts/open-sans';
@import '~open-sans-fonts/open-sans.scss';
@import '~minireset.css/minireset.sass';
@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/grid/columns.sass';
@import '~buflib/dist/style.min.css';
@import '~bulma/bulma';

@font-face {
  font-family: ARSMaquettePro;
  src: url('statics/ARSMaquettePro-Regular.otf') format('opentype');
}

@font-face {
  font-family: ARSMaquettePro;
  font-weight: bold;
  src: url('statics/ARSMaquettePro-Bold.otf') format('opentype');
}

@font-face {
  font-family: ARSMaquettePro;
  font-style: normal;
  src: url('statics/ARSMaquettePro-RegularItalic.otf') format('opentype');
}

// global styling
body,
html {
  font-family: 'ARSMaquettePro', 'Open Sans', Arial, sans-serif;
}

img {
  max-width: 100%;
}

button,
a {
  &:focus {
    outline: 1px solid black;
  }
}

ul {
  list-style-type: disc;
  margin-left: 24px;
}

.MuiTooltip-tooltip {
  font-size: 12px !important;
}