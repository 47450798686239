.stopp-punkt-card {
  margin: 16px 0;
  border: 1px solid #737373;
  cursor: pointer;
  legend {
    display: flex;
    align-items: center;
    margin-left: 8px;

    & > * {
      margin: 0px 4px;
    }

    svg {
      background-color: white;
      path {
        fill: #2583ab;
      }
    }

    span {
      padding: 4px 8px;
      color: #1a1a1a;
      background-color: #65A4BF;
      text-transform: uppercase;
    }
  }

  &__content {
    padding: 16px;
  }

  &__expand {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0 16px 16px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__sporsmal {
    padding: 16px;

    p svg {
      margin-right: 8px;
    }
  }
}
